import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class CustomersProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/customers', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/customers/${id}`)
  }

  createItem (form) {
    this.setHeader(getAuthToken())
    return this.post('/customers', form)
  }

  updateItem (form) {
    this.setHeader(getAuthToken())
    return this.put(`/customers/${form.id}`, form)
  }

  deleteItem (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/customers/${id}`)
  }

  sync () {
    this.setHeader(getAuthToken())
    return this.post('/customers/migrate-bc')
  }
}

export default CustomersProvider
