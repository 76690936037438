<template>
  <v-container
    class="container-bg rounded elevation-3"
    fluid>
    <v-form
      ref="customer-form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col cols="12">
          <h2>จัดการ Customer</h2>
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูล Customer
          </span>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.number"
            label="Customer No."
            placeholder="CDXXXXXX"
            color="secondary"
            :rules="textBoxRules"
            dense
            outlined
            :disabled="loading || isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-autocomplete
            v-model="formData.name"
            :items="warehouses"
            item-text="code"
            item-value="code"
            label="ชื่อ"
            color="secondary"
            outlined
            dense
            hide-details
            :rules="textBoxRules"
            :disabled="loading"
            @input="selectWarehouse($event)">
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.searchName"
            :rules="[ ...textBoxRules, maxLengthRule(100) ]"
            label="ชื่อสำหรับค้นหา"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.taxRegistrationNumber"
            label="รหัสประจำตัวผู้เสียภาษี"
            color="secondary"
            :rules="[ ...textBoxRules, maxLengthRule(20) ]"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.phoneNumber"
            :rules="[ maxLengthRule(30) ]"
            label="เบอร์โทร"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.branch"
            :rules="[ ...textBoxRules, maxLengthRule(50) ]"
            label="สาขา"
            placeholder="00001"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-textarea
            v-model="formData.address"
            label="ที่อยู่"
            color="secondary"
            :rules="[ ...textBoxRules, maxLengthRule(100) ]"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-textarea
            v-model="formData.address2"
            :rules="[ maxLengthRule(50) ]"
            label="ที่อยู่ (เพิ่มเติม)"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูลภาษี
          </span>
          <v-divider />
        </v-col>
        <!-- <v-col
          cols="12"
          md="3"
          class="switch-container">
          <v-switch
            v-model="formData.priceIncludesTax"
            label="ราคารวม VAT"
            color="secondary"
            :disabled="loading"
            hide-details />
        </v-col>
        <v-col
          cols="12"
          md="9">
        </v-col> -->
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.custPostingGroup"
            label="กลุ่ม Customer"
            color="secondary"
            :items="customerGroup"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.paymentCondition"
            label="เงื่อนไขการชำระเงิน"
            color="secondary"
            :items="paymentConditions"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.genBusPostingGroup"
            label="กลุ่ม Gen. Bus."
            color="secondary"
            :items="generalGroup"
            item-text="text"
            item-value="value"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.vatBusPostingGroup"
            label="ประเภท VAT"
            color="secondary"
            :items="vatGroup"
            item-text="text"
            item-value="value"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center align-center">
          <v-btn
            class="px-8"
            :loading="loading"
            color="success"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CustomersProvider from '@/resources/CustomersProvider'

const CustomersService = new CustomersProvider()

export default {
  data () {
    return {
      loading: false,
      isEdit: false,
      textBoxRules: [
        (v) => !!v || 'โปรดกรอก Field นี้'
      ],
      formData: {
        number: '',
        name: '',
        searchName: '',
        name2: '',
        address: '',
        address2: '',
        city: '',
        phoneNumber: '',
        creditLimit: '',
        custPostingGroup: 'POS_DOMESTIC',
        currencyCode: '',
        paymentTermsCode: '',
        salesPersonCode: '',
        countryLetterCode: '',
        paymentMethodCode: '',
        priceIncludesTax: true,
        faxNumber: '',
        taxRegistrationNumber: '',
        genBusPostingGroup: 'POS_DOMESTIC',
        postalCode: '',
        county: '',
        email: '',
        homePage: '',
        vatBusPostingGroup: 'DOMESTIC',
        companyLocation: '',
        mobileNumber: '',
        paymentCondition: 'จ่ายทันทีเมื่อรับของ',
        billingCondition: '',
        branch: '',
        type: 'Company'
      },
      paymentConditions: [
        'จ่ายทันทีเมื่อรับของ',
        'จ่ายก่อนรับของ'
      ],
      customerGroup: [
        'POS_DOMESTIC',
        'ONLINE_DOMESTIC'
      ],
      vatGroup: [
        {
          text: 'ภายในประเทศ',
          value: 'DOMESTIC'
        },
        {
          text: 'ต่างประเทศ',
          value: 'FOREIGN'
        }
      ],
      generalGroup: [
        {
          text: 'ภายในประเทศ (POS)',
          value: 'POS_DOMESTIC'
        },
        {
          text: 'ต่างประเทศ (POS)',
          value: 'POS_FOREIGN'
        },
        {
          text: 'ภายในประเทศ (ONLINE)',
          value: 'ONLINE_DOMESTIC'
        },
        {
          text: 'ต่างประเทศ (ONLINE)',
          value: 'ONLINE_FOREIGN'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      warehouse: 'Store/warehouse'
    }),
    warehouses () {
      return this.warehouse.filter((w) => w.id)
    }
  },
  mounted () {
    if (this.$route.params?.id) {
      this.isEdit = true
      this.getItem()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    maxLengthRule (length) {
      return (v) => v.length <= length || `Field นี้ต้องยาวไม่เกิน ${length} ตัวอักษร`
    },
    async getItem () {
      try {
        this.loading = true

        const { data } = await CustomersService.getItemById(this.$route.params.id)

        this.formData = data
      } catch (error) {
        console.error('getItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      const validated = await this.$refs['customer-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true

        if (this.isEdit) {
          await CustomersService.updateItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'แก้ไข Customer สำเร็จ',
            type: 'success'
          })
        } else {
          await CustomersService.createItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'สร้าง Customer สำเร็จ',
            type: 'success'
          })
        }

        this.$router.push({ name: 'PurchasesCustomers' })
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    selectWarehouse (val) {
      const warehouse = this.warehouses.find((w) => w.code === val)

      const addressArray = [
        warehouse?.address2 || '',
        warehouse?.subDistrict || '',
        warehouse?.district || '',
        warehouse?.stateProvince || '',
        warehouse?.postcode || ''
      ].filter(Boolean).join(', ')

      this.formData.companyLocation = warehouse?.code || this.formData.companyLocation || ''
      this.formData.searchName = warehouse?.name || this.formData.searchName || ''
      this.formData.taxRegistrationNumber = warehouse?.taxId || this.formData.taxRegistrationNumber || ''
      this.formData.address = warehouse?.address || this.formData.address || ''
      this.formData.address2 = addressArray || this.formData.address2 || ''
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.header-line {
  position: relative;
}
.header-line span {
  font-weight: 500;
  background-color: white;
  padding-right: 8px;
  position: absolute;
  left: 12px;
  top: 0;
}
</style>

<style>
.switch-container .v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
